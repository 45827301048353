import React from 'react';
import { InfoSection, NavbarCN } from '../../components';

import { homeObjOne, homeObjThree, homeObjTwo, homeObjFour, homeObjFive , homeObjSix} from './DataCN';

const HomeCN = () => {
    return (
        <>
            <NavbarCN />
            <InfoSection {...homeObjOne} />
            {/* <InfoSection {...homeObjThree} />
            <InfoSection {...homeObjTwo} />
            <InfoSection {...homeObjFour} />
            <InfoSection {...homeObjFive} />
            <InfoSection {...homeObjSix} /> */}
        </>
    )
}

export default HomeCN;